import Step1Img from "../assets/img/steps/holding_pH.jpg"
import Step2Img from "../assets/img/steps/toilet.jpg"
import Step3Img from "../assets/img/steps/insert-1.jpg"
import Step4Img from "../assets/img/steps/insert-2.jpg"
import Step5Img from "../assets/img/steps/holding_pH.jpg"
import Step6Img from "../assets/img/steps/pH_on_Scancard.jpg"
import Step7Img from "../assets/img/steps/ph_on_card_with_phone.jpg"


const PH_STEPS = [
	{
		img: Step1Img,
		img_alt: "IMG opening pouches",
		title: "Open the package, remove a test and open the foil. Only touch the test using the round handle area.",
		next_btn: "Next",
		description: "Hold the test's round handle between your thumb and index finger - make sure the test area does not come into contact with other liquids."
	},
	{
		img: Step2Img,
		img_alt: "Diagram style IMG",
		title: "Sit with your knees spread or with one foot on the toilet seat or a firm surface.",
		next_btn: "Next",
		description: "Now, use your free hand to gently spread the vaginal opening, carefully separating the outer labia."
	},
	{
		img: Step3Img,
		img_alt: "Diagram style IMG",
		title: "Holding the round handle, insert the test area gently into the vagina.",
		next_btn: "Next",
		description: "Do  this the same way that you would use a tampon, about an inch into the vagina and not further than the round handle area. This should be completely pain free!"
	},
	{
		img: Step4Img,
		img_alt: "diagram of strip insertion",
		title: "Press the inserted part against the back wall of the vagina with light pressure for 10 seconds.",
		next_btn: "Next",
		description: "Push back towards the rectum, so that the test measurement area becomes well moistened. Be sure not to touch the tip of the cotton swab against anything so the sample stays clean."
	},
	{
		img: Step5Img,
		img_alt: "IMG of pH strip",
		title: "Carefully remove the test from your vagina.",
		next_btn: "Next",
		description: "The color on the test area should start changing right away."
	},
	{
		img: Step6Img,
		img_alt: "IMG placing strip on color card",
		title: "Lay the pH Test Strip with the test pad facing up onto the color card panel.",
		next_btn: "Next",
		description: "Be sure the test pad is centered on the card as shown."
	},
	{
		img: Step7Img,
		img_alt: "IMG of taking picture of card",
		title: "Take a picture of the test strip and color card.",
		next_btn: "Take My Picture",
		description: "You’ll capture the image on the next screen. Be sure the picture is clear, and in focus."
	},
];

const SYMPTOMS = [
	{
		title: "Itching",
		question: "Are you currently experiencing vaginal itching",
		options: ["Yes", "No"]
	},
	{
		title: "Discharge",
		question: "Which of the following best describes your vaginal discharge at this moment?”",
		options: [
			"Thick and white, cottage-cheese like. No odor.",
			"Greyish white and runny. Foul, fishy odor.",
			"Green, yellow, or brown (with or without odor).",
			"None of the above."]
	}
];

const SERVER_URL = process.env.REACT_APP_API;

export {
	PH_STEPS,
	SYMPTOMS,
	SERVER_URL
}