import { useState, useEffect } from 'react';


const AttentivePage = () => {

  return (
    <>
      
      <iframe
        src="https://getstix.com/pages/sign-up"
        style={{ width: '100%', height: '100%' }}
        title="Sign Up"
      ></iframe>

    </>
  )

}

export default AttentivePage;
