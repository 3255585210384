import { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import StartPage from './pages/StartPage';
import AttentivePage from './pages/AttentivePage';
import ResultPage from './pages/ResultPage';
import CameraPage from './pages/CameraPage';
import PHStepsPage from './pages/PHStepsPage';
import DownloadPage from './pages/DownloadPage';
import posthog from 'posthog-js';

const MyRoutes = ({setDisabledBack, clickBack, checkUrl}) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const isFirstPage = location.key === '1'; // Check if it's the first page of the history
        setDisabledBack(isFirstPage)
        const check = location.pathname.indexOf("download") > -1 ? true : false;
        checkUrl(check)
    }, [location]);

    useEffect(() => {
        if (clickBack > 0) {
            navigate(-1);
        }
    }, [clickBack])
    
    useEffect(() => {
      if (['prod'].includes(process.env.REACT_APP_ENV)) {
        posthog.capture('$pageview')
        console.log("PostHog: pageview")
      }
    }, [location]);
    
    return (
        <Routes>
            <Route path="/start" element={<StartPage />} />
            <Route path="/ph" element={<PHStepsPage />} />
            <Route path="/info" element={<AttentivePage />} />
            <Route path='/camera' element={<CameraPage />} />
            <Route path='/result' element={<ResultPage />} />
            <Route path='/download' element={<DownloadPage />} />
            <Route path="*" element={<Navigate to="/start" replace />} />
        </Routes>
    )
}

export default MyRoutes