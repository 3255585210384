import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { SERVER_URL, SYMPTOMS } from "../const/const";

import ProtectionImg from "../assets/img/Rectangle 9765.png";
import WellnessKitImg from "../assets/img/vaginal-wellness-kit.png";
import ProbioticImg from "../assets/img/Rectangle 9764.png";
import PurpleBtn from "../Components/PurpleBtn";
import TransparentBtn from "../Components/TransParentBtn";
import SmileImg from "../assets/img/smile.png";
import ScanbaseImg from "../assets/img/scanbase.png";
import SpinnerImg from "../assets/img/spinner.gif";

const TIMER_LENGTH = process.env.REACT_APP_EXPIRED;

function getFormattedDate() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
}

const ResultPage = () => {
	const navigate = useNavigate();
	const [resultImg, setResultImg] = useState(null);
	const [ph, setPH] = useState();
	const [isExpired, setIsExpired] = useState(false);
	const [img, setImg] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [teleImg, setTeleImg] = useState(null);
	const currentDate = getFormattedDate();

	const goToPrescription = () => {
		window.open(process.env.REACT_APP_DRB, "_blank", "noreferrer")
		// navigate("/download", {state: { data: teleImg }});
	}
	
	const goToShop = () => {
		window.open("https://getstix.co/collections/urinary-tract-health", "_blank", "noreferrer")
	}

	const handleFileChange = async (event) => {
    localStorage.setItem("result", null);
    const formData = new FormData();
    setImg(URL.createObjectURL(event.target.files[0]));
    formData.append("image", event.target.files[0])
    // event.target.value = ''
    try {
      setIsLoading(true);
      const res = await axios.post(SERVER_URL, formData);
      await new Promise(r => setTimeout(r, 2000));
			localStorage.setItem("result", res.data.strip_image); 
			localStorage.setItem("teleImg", res.data.teledoc_image); 
      localStorage.setItem("ph", res.data.PH);
			setResultImg(`data:image/jpeg;base64,${res.data.strip_image}`);
			setPH(res.data.PH);
			setTeleImg(`data:image/jpeg;base64,${res.data.teledoc_image}`);
    } catch (error) {
      localStorage.setItem("result", null);
    }
    setIsLoading(false);
    // fileInput.remove();

  }

	const checkExpired = () => {
		const current = Math.floor(Date.now() / 1000);
    const target = parseInt(localStorage.getItem("expired"));
    if (target - current <= 0 ) {
			setIsExpired(true);
		} else {
			document.getElementById("photoTaker").click()
		}
	}

	useEffect(() => {
		if (localStorage.getItem("result") !== "null" && localStorage.getItem("result") !== null) {
			setResultImg(`data:image/jpeg;base64,${localStorage.getItem("result")}`);
			setTeleImg(`data:image/jpeg;base64,${localStorage.getItem("teleImg")}`)
			setPH(localStorage.getItem("ph"));
		} else {
			const current = Math.floor(Date.now() / 1000);
			localStorage.setItem("expired", current + parseInt(TIMER_LENGTH));
			const quiz = localStorage.getItem("quiz");
			setResultImg(null);
		}
	}, []);

	// 0 - Texture of discharge
	// 1 - Color of discharge
	// 2 - Odor of discharge
	const isDischargeNormal = () => {
		const quizStr = localStorage.getItem("quiz");
		const quizResults = quizStr.split(',');
		const texture = quizResults[0]
		const color = quizResults[1]
		const discharge = quizResults[2]

		if (texture === "2") {
			return true
		}
		if (color === "0" || color === "1") {
			return true
		}
		if (discharge === "3") {
			return true
		}
		return false;
	}

	const hasVaginalItching = () => {
		const quizStr = localStorage.getItem("quiz");
		const quizResults = quizStr.split(',');
		const hasItching = quizResults[0];

		if (hasItching === "0") {
			return true;
		}
		return false;
	}

	const discharge = () => {
		const quizStr = localStorage.getItem("quiz");
		const quizResults = quizStr.split(',');
		const dcharge = quizResults[1];

		return dcharge;
	}


	const getResult = () => {
		if (
				((ph === "0" || ph === "1" || ph === "2") && hasVaginalItching() === true) ||
				((ph === "0" || ph === "1" || ph === "2") && hasVaginalItching() === false && discharge() === "0")
				) {
			return {
				title: "Your results suggest a yeast infection.",
				desc: "We can connect you with a doctor to find the best course of treatment. Click “Talk with a Doctor” below for next steps.",
				scriptionButtonText:"Get a Prescription"
			}
		}

		if ((ph === "0" || ph === "1" || ph === "2") && hasVaginalItching() === false && (discharge() === "1" || discharge() === "2")) {
			return {
				title: "Looks like you do not have a yeast infection, but you do have signs of another vaginal infection",
				desc: "We can connect you with a doctor to find the best course of treatment. Click “Talk with a Doctor” below for next steps.",
				scriptionButtonText:"Talk with a Doctor"
			}
		}

		if ((ph === "0" || ph === "1" || ph === "2") && hasVaginalItching() === false && discharge() === "3") {
			return {
				title: "No sign of a yeast or vaginal infection.",
				desc: "We can connect you with a doctor to find the best course of treatment. Click “talk with a doctor” below for next steps",
				scriptionButtonText:"Talk with a Doctor"
			}
		}

		if (ph === "3" || ph === "4" || ph === "5" || ph === "6") {
			return {
				title: "Looks like you do not have a yeast infection, but you do have signs of another vaginal infection",
				desc: "We can connect you with a doctor to find the best course of treatment. Click “talk with a doctor” below for next steps",
				scriptionButtonText:"Talk with a Doctor"
			}
		}
}



	return(
		<>
		{
			resultImg
				? <>
					<div className='relative w-full h-32 xxs:h-40 xs:h-60 px-4 bg-[#FFF4EA] relative flex flex-col items-center justify-between xxxs:text-sm xxs:text-lg xs:text-xl'>
						<div className="w-full flex justify-between xxxs:py-1 xxs:py-4 xs:py-6 domaine-regular font-bold"><span>Results</span><span>{currentDate}</span></div>
						<div className="relative w-full h-20 xxs:h-24 xs:h-40 bg-[#2694C0] rounded-t-xl">
							<img style={{boxShadow: "-10px 0px 8px 0px #0000006b"}} className="absolute bottom-0 right row-center h-16 xxs:h-20 xs:h-36" src={resultImg} alt="result" />
						</div>
					</div>
					<div className='bg-[#fff4ea] flex-1 flex flex-col items-center xxxs:pb-2 xs:pb-10 px-4'>
						<div className="w-full bg-white rounded-b-xl xxxs:p-1 xxs:p-2 xs:p-8 text-center">
							<div className="beatrice-font xxxs:text-sm xxs:text-lg xs:text-2xl">
								{
									getResult().title
								}
							</div>
							<div className="domaine-regular xxxs:text-sm xxs:text-lg xxs:leading-tight xs:text-xl">
							
								{
									getResult().desc
								}
							</div>
						</div>
						<p className="beatrice-font font-bold xxxs:text-sm xxs:text-lg xs:text-2xl xxxs:py-1 xxs:py-4">Doctors recommend:</p>
<div className="flex-1 grid grid-cols-3 gap-4 beatrice-font xxxs:text-xs xxs:text-base font-bold text-center xxxs:mb-2 xxs:mb-4 xs:mb-8">
  <a className="flex flex-col" href="https://getstix.co/products/vaginal-health-probiotic" target="_blank" rel="noreferrer">
    <div className="bg-white xxxs:mb-2 xxs:mb-4 rounded-lg aspect-square overflow-hidden">
      <img src={ProbioticImg} alt="probiotic_img" className="object-cover w-full h-full" />
    </div>
    <div className="xxs:leading-tight">Vaginal Health Probiotic</div>
  </a>
  <a className="flex flex-col" href="https://getstix.co/products/uti-daily-protection-supplement" target="_blank" rel="noreferrer">
    <div className="bg-white xxxs:mb-2 xxs:mb-4 rounded-lg aspect-square overflow-hidden">
      <img src={ProtectionImg} alt="protection_img" className="object-cover w-full h-full" />
    </div>
    <div className="xxs:leading-tight">UTI Daily Protection</div>
  </a>
  <a className="flex flex-col" href="https://getstix.co/products/vaginal-wellness-combo" target="_blank" rel="noreferrer">
    <div className="bg-white xxxs:mb-2 xxs:mb-4 rounded-lg aspect-square overflow-hidden">
      <img src={WellnessKitImg} alt="wellness_kit_img" className="object-cover w-full h-full" />
    </div>
    <div className="xxs:leading-tight">Vaginal Wellness Kit</div>
  </a>
</div>
						<PurpleBtn func={goToPrescription}>{getResult().scriptionButtonText}</PurpleBtn>
						<TransparentBtn className="mt-2" func={goToShop}>Shop Winx Health <FontAwesomeIcon className='ml-2' icon={faArrowRight} /></TransparentBtn>
					</div>
				</>
				: !isExpired
					? isLoading
						? <div className='w-full h-full bg-[#6E66BC] flex flex-col items-center justify-between beatrice-font text-white'>
							<img className='w-40 xxs:w-52 xs:w-60 mt-16' src={img} alt='img' />
							<div className='flex flex-col items-center'>
								<img className='w-40' src={SpinnerImg} alt='spinner' />
								<div className='beatrice-font text-xl'>AI Analysis Engaged</div>
								<div className='domaine-regular text-lg'>Just a moment...</div>
							</div>
							<div className='flex my-8'>
							</div>
						</div>
						: <div className='bg-[#fff4ea] flex-1 flex flex-col pb-10 px-4'>
						<input id="photoTaker" type="file" accept="image/*" className="hidden" onChange={handleFileChange}></input>
						<div className="flex-1 flex flex-col py-4 items-center justify-center text-center beatrice-font text-2xl">
							<img className="mb-6" src={SmileImg} alt="smile" />
							<div className="mb-6 font-bold text-3xl">We need a higher quality image</div>
							<div className="domaine-regular">1. Hold the camera steady.</div>
							<div className="mb-6 domaine-regular">2. Be sure you're in good light.</div>
						</div>
						<PurpleBtn func={checkExpired}>Take Picture</PurpleBtn>
					</div>
					: <div className='bg-[#fff4ea] flex-1 flex flex-col pb-10 px-4'>
						<div className="flex-1 flex flex-col py-4 items-center justify-center text-center beatrice-font text-2xl">
							<img className="mb-6" src={SmileImg} alt="smile" />
							<div className="mb-6 font-bold text-3xl">Too much time has passed. Your test has expired.</div>
							<div className="domaine-regular">Please try again with a new strip.</div>
						</div>
						<PurpleBtn>Try Again</PurpleBtn>
					</div>
		}
			{/* <div className='bg-[#fff4ea] flex-1 flex flex-col pb-10 px-4'>
				<div className="flex-1 flex flex-col py-4 items-center justify-center text-center beatrice-font text-2xl">
					<img className="mb-6" src={SmileImg} alt="smile" />
					<div className="mb-6 font-bold text-3xl">Too much time has passed. Your test has expired.</div>
					<div className="domaine-regular">Please try again with a new strip.</div>
				</div>
				<PurpleBtn>Try Again</PurpleBtn>
			</div> */}
		</>
	)
}

export default ResultPage;