import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import posthog from 'posthog-js';
import { SERVER_URL } from "../const/const";
import PurpleBtn from "../Components/PurpleBtn";
import SymptomContainer from "../containers/SymptomContainer";
import StepsContainer from "../containers/StepsContainer";
import { PH_STEPS } from "../const/const";
import StixPHTestImg from "../assets/img/stix-ph-test.png";
import SpinnerImg from "../assets/img/spinner.gif";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TransparentBtn from "../Components/TransParentBtn";


const PHStepsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSymptom, setIsSymptom] = useState(false);
  const [step, setStep] = useState(1);
  const [isStep, setIsStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState(null);

  const handleFileChange = async (event) => {
    localStorage.setItem("result", null);
    const formData = new FormData();
    setImg(URL.createObjectURL(event.target.files[0]));
    formData.append("image", event.target.files[0])
    // event.target.value = ''
    try {
      setIsLoading(true);
      const res = await axios.post(SERVER_URL, formData);
      await new Promise(r => setTimeout(r, 2000));
      localStorage.setItem("result", res.data.strip_image);
      localStorage.setItem("teleImg", res.data.teledoc_image);
      localStorage.setItem("ph", res.data.PH);
      if (['prod'].includes(process.env.REACT_APP_ENV)) {
        posthog.capture('scan_succeeded')
      }
      window.heap.track('scan_succeeded');
    } catch (error) {
      localStorage.setItem("result", null);
      if (['prod'].includes(process.env.REACT_APP_ENV)) {
        posthog.capture('scan_failed')
      }
    }
    setIsLoading(false);
    navigate("/result")
    // fileInput.remove();
  }

  const goToHome = () => {
    navigate("/start");
    setIsStep(false);
    setIsSymptom(false);
  }

  const goToStep = () => {
    navigate("/ph?stage=step&step=1");
  }
  
  const goToSymptom = () => {
    navigate("/ph?stage=symptom&step=2");
  }

  const [currentStep, setCurrentStep] = useState(1);

  const onNextBtn = () => {
    if (currentStep < PH_STEPS.length) {
      navigate(`/ph?stage=step&step=${currentStep + 1}`);
    } else {
      document.getElementById("photoTaker").click()
    }
  }

  const onPrevBtn = () => {
    if (currentStep > 1) {
      navigate(`/ph?stage=step&step=${currentStep - 1}`);
    } else {
      goToSymptom()
    }
  }

  useEffect(() => {
    const stage = searchParams.get("stage");
    if (stage) {
      if (stage === "symptom") {
        setIsSymptom(true);
        setIsStep(false);
      }
      if (stage === "step") {
        setIsSymptom(false);
        setIsStep(true);
      }
      if (searchParams.get("step")) {
        setCurrentStep(parseInt(searchParams.get("step")));
        setStep(parseInt(searchParams.get("step")));
      }
    }
  }, [searchParams])

  return (
    <>
    {
      !isLoading
        ? <>
          {
            !isSymptom &&
              <div className='flex relative w-full bg-[#e8e4f2] flex-col items-center justify-center text-4xl font-bold z-10'>
                {
                  !isStep
                    ? <>
                      <div className='text-[#ff9068] beatrice-font' >Vaginal Health</div>
                      <div className='domaine-bold xxxs:mb-16 xxs:mb-0'>Test & Treat</div>
                      <img className='absolute h-full hidden' src={PH_STEPS[0].img} alt={PH_STEPS[0].img_alt} />
                    </>
                    : PH_STEPS.map((item, index) =>
                      <img className={`flex max-h-80 ${index === step - 1 ? 'block' : 'hidden'}`} src={item.img} alt={item.img_alt} />
                    )
                    
                }
                {
                  !isStep &&
                    <img className='absolute xxxs:w-20 xxs:w-48 xs:w-60 xs:top-48 top-40' src={StixPHTestImg} alt='stix-uti-test' />
                }
              </div>
          }
          <div className='relative overflow-auto bg-[#fff4ea] flex-1 flex flex-col px-4'>
            {
              !isSymptom && !isStep && 
                <>
                  <div className="flex-1"></div>
                  <PurpleBtn func={() => navigate('/ph?stage=symptom&step=1')} >Get Started</PurpleBtn>
                </>
            }
            {
              isSymptom && <SymptomContainer goToStep={goToStep} goToHome={goToHome} />
            }
            {
              isStep && 
                <StepsContainer 
                  handleFileChange={handleFileChange}
                  STEPS={PH_STEPS} 
                  setStep={setStep}
                  goToSymptom={goToSymptom} />
            }
          </div>

          {
            isStep &&
            <footer className='bg-[#fff4ea] px-4 pb-4'>
                <PurpleBtn func={onNextBtn} >
                  { PH_STEPS[currentStep - 1].next_btn }
                </PurpleBtn>
                {
                  currentStep < PH_STEPS.length &&
                    <TransparentBtn className="mt-2" func={onPrevBtn} > 
                      <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
                      Go Back
                    </TransparentBtn>
                }
            </footer>
          }

        </>
        : <div className='w-full h-full bg-[#6E66BC] flex flex-col items-center justify-between beatrice-font text-white'>
          <img className='w-40 xxs:w-52 xs:w-60 mt-16' src={img} alt='img' />
          <div className='flex flex-col items-center'>
            <img className='w-40' src={SpinnerImg} alt='spinner' />
            <div className='beatrice-font text-xl'>AI Doc Engaged</div>
            <div className='domaine-regular text-lg'>Just a moment...</div>
          </div>
          <div className='flex my-8'>
          </div>
        </div>
    }
    </>
  );
}

export default PHStepsPage;