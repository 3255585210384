import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PurpleBtn from '../Components/PurpleBtn';
import PHTestImg from "../assets/img/stix-ph-test.png";



const StartPage = (props) => {
    const navigate = useNavigate();
    const imageCss = false? "w-32 xxxs:w-40 xxs:w-48 xs:w-56 mr-10" : "w-32 xxxs:w-32 xxs:w-40 xs:w-48 mt-5";

    const onNextBtn = () => {
        navigate("/ph?stage=symptom&step=1");
        return;
    }

    return (
        <>
            <div class={`flex w-full h-1/3 flex-col items-center justify-center bg-[#e8e4f2] text-4xl font-bold`}>
                <div class={`relative top-40  beatrice-font text-[#ff9068]`}>
                    Winx Health
                </div>
                <div class={`relative top-40 domaine-bold xxxs:mb-16 xxs:mb-0`}>
                    Vaginal Health Test
                </div>
                <div class={`relative top-40 mt-4 px-4 domaine items-center text-xl xxxs:mb-16 xxs:mb-0`}>
                    Your final results are made up of your pH test results and your symptoms. Let’s get started!
                </div>
                <div className={`relative top-40  ${imageCss}`}>
                  <img className="" src={PHTestImg} alt='stix-uti-test' />
                </div>
            </div>
            <div class="flex-1 flex flex-col items-center justify-end bg-[#FFF4EA] text-4xl pb-6 px-4 font-bold">
                <div class="flex-grow"></div>
                <PurpleBtn disabled={false} func={onNextBtn}>
                    Get Started
                </PurpleBtn>
            </div>
        </>
    )
}

export default StartPage;
